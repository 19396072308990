import { Box } from '@deckee/deck-hand';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import useTripStatus, {
  calculateBackgroundColor,
} from '../hooks/use-trip-status';
import { Trip } from '../interfaces/trip';

interface StatusChipDivProps extends HTMLAttributes<HTMLElement> {
  isLoggedOff: boolean;
  timeDiff: number;
}

const StatusChipDiv = styled.div<StatusChipDivProps>`
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;
  text-align: center;

  color: ${({ theme }) => theme.colors.white};
  width: 173px;
  height: 40px;
  background: ${({ isLoggedOff, timeDiff }) =>
    calculateBackgroundColor(isLoggedOff, timeDiff)};
  border-radius: 8px;
`;

interface StatusChipProps {
  trip: Trip;
  className?: string;
}

const StatusChip = ({
  trip,
  className,
}: StatusChipProps): React.ReactElement => {
  const { timeDiff, statusText, isLoggedOff } = useTripStatus(trip);
  return (
    <StatusChipDiv
      className={className}
      isLoggedOff={isLoggedOff}
      timeDiff={timeDiff}
    >
      <Box fontWeight="bold">{isLoggedOff ? 'Completed' : statusText}</Box>
      <Box>{isLoggedOff}</Box>
    </StatusChipDiv>
  );
};

export default StatusChip;
