import { useEffect, useState } from 'react';
import theme from '../components/theme';
import { Trip } from '../interfaces/trip';
import { calculateTimeDifference } from '../lib/dates';
import { getTripStatus, TripStatus } from '../lib/trip';

export function calculateBackgroundColor(
  isLoggedOff: boolean,
  timeDiff: number,
) {
  if (isLoggedOff) {
    return theme.colors.info;
  }

  const status = getTripStatus(timeDiff);

  switch (status) {
    case TripStatus.SAR30Incident:
      return theme.colors.danger;
    case TripStatus.Overdue15:
      return theme.colors.warning;
    case TripStatus.Due:
      return theme.colors.success;
    default:
      return undefined;
  }
}

export function getBackgroundColors(isLoggedOff: boolean, timeDiff: number) {
  if (isLoggedOff) {
    return { status: theme.colors.info, statusLight: theme.colors.infolight };
  }

  const status = getTripStatus(timeDiff);

  switch (status) {
    case TripStatus.SAR30Incident:
      return {
        status: theme.colors.danger,
        statusLight: theme.colors.dangerlight,
      };
    case TripStatus.Overdue15:
      return {
        status: theme.colors.warning,
        statusLight: theme.colors.warninglight,
      };
    case TripStatus.Due:
      return {
        status: theme.colors.success,
        statusLight: theme.colors.successlight,
      };
    default:
      return undefined;
  }
}

// Format minutes into a human readable string (e.g. 1h 30m). Needs to include days, hours, and minutes as long as each isn't 0. Sould only include 2 of the units.
export function formatMinutes(mins: number) {
  // Drop the decimal part (we don't want to round)
  const minutes = Math.trunc(mins);
  const days = Math.floor(minutes / 1440);
  const hours = Math.floor((minutes % 1440) / 60);
  const remainingMinutes = (minutes % 60).toFixed(0);

  if (days > 0) return `${days}d ${hours}h`;
  if (hours > 0) return `${hours}h ${remainingMinutes}m`;
  return `${remainingMinutes}m`;
}

export function formatMinutesAlwaysPositive(mins: number) {
  return formatMinutes(Math.abs(mins));
}

function calculateStatusText(minutesDifferent: number, loggedOff = false) {
  if (loggedOff) return 'Logged off';

  const status = getTripStatus(minutesDifferent);

  switch (status) {
    case TripStatus.Due:
      if (minutesDifferent == 0) return 'Due now';
      if (minutesDifferent < 0)
        return `Overdue ${formatMinutesAlwaysPositive(minutesDifferent)}`;

      return `Due in ${formatMinutesAlwaysPositive(minutesDifferent)}`;
    case TripStatus.Overdue15:
      return `Overdue ${formatMinutesAlwaysPositive(minutesDifferent)}`;
    case TripStatus.SAR30Incident:
      return `SAR Incident ${formatMinutesAlwaysPositive(minutesDifferent)}`;
    default:
      return undefined;
  }
}

const useTripStatus = (trip?: Trip) => {
  const [timeDiff, setTimeDiff] = useState(null);
  const [statusText, setStatusText] = useState(null);
  const [isLoggedOff, setIsLoggedOff] = useState(false);

  const status = getTripStatus(timeDiff);
  const sarIncident = status === TripStatus.SAR30Incident && !trip?.endedTime;

  useEffect(() => {
    const loggedOff = !!trip?.endedTime;
    const calculatedTimeDiff = calculateTimeDifference(trip?.endTime);
    const calculatedStatusText = calculateStatusText(
      calculatedTimeDiff,
      loggedOff,
    );
    setTimeDiff(calculatedTimeDiff);
    setIsLoggedOff(loggedOff);
    setStatusText(calculatedStatusText);

    const interval = setInterval(() => {
      const interalLoggedOff = !!trip?.endedTime;
      const intervalCalculatedTimeDiff = calculateTimeDifference(trip?.endTime);
      const intervalCalculatedStatusText = calculateStatusText(
        intervalCalculatedTimeDiff,
        interalLoggedOff,
      );
      setTimeDiff(intervalCalculatedTimeDiff);
      setIsLoggedOff(interalLoggedOff);
      setStatusText(intervalCalculatedStatusText);
    }, 60000);
    return () => clearInterval(interval);
  }, [trip?.endedTime, trip?.endTime]);

  return {
    timeDiff,
    isLoggedOff,
    statusText,
    sarIncident,
    colors: getBackgroundColors(isLoggedOff, timeDiff),
    statusColor: calculateBackgroundColor(isLoggedOff, timeDiff),
  };
};

export default useTripStatus;
