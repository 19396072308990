import { Box, Heading, MessageBlock } from '@deckee/deck-hand';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import useLoloMember from '../../hooks/use-lolo-member';
import useTrips from '../../hooks/use-trips';
import { TripSearchFields } from '../../interfaces/trip';
import TripList from '../organisms/trip-list';
import TripSearchControls, {
  TripFilters,
} from '../TripSearchControls/TripSearchControls';

interface SearchableTripsListProps {
  // These filters are always applied to the search criteria
  tripFilters?: Partial<TripFilters>;

  // These filters are always applied to the search results, but does not get applied to "all results"
  searchTripFilters?: Partial<TripFilters>;
  sourceUrl?: string;
}

const SearchableTripsList = ({
  tripFilters,
  searchTripFilters,
  sourceUrl,
}: SearchableTripsListProps) => {
  const router = useRouter();

  const {
    member,
    isLoading: isMemberLoading,
    error: memberError,
  } = useLoloMember();

  const {
    trips,
    apiResponse: data,
    error,
    isLoading: isLoadingTrips,
    setFilters,
    filters,
  } = useTrips(member, tripFilters);

  const filterActive =
    filters.searchText !== '' ||
    searchTripFilters?.groups?.length > 0 ||
    searchTripFilters?.status?.length > 0;

  // Keep a reference to the loaded data so when the filters change we can still display the old data.
  const initialData = useRef(undefined);
  if (data && !filterActive) {
    initialData.current = data;
  }

  useEffect(() => {
    setFilters((prevValue) => ({
      ...prevValue,
      ...searchTripFilters,
    }));
  }, [searchTripFilters]);

  const handleSetFilters = (newFilters: Partial<TripFilters>) => {
    setFilters({
      ...{ searchField: TripSearchFields.details },
      ...filters,
      ...newFilters,
    });
  };
  const onPageChanged = (pageData: {
    currentPage: number;
    totalPages: number;
    pageLimit: number;
  }) => {
    const { currentPage } = pageData;

    const start = (currentPage - 1) * filters.limit;

    handleSetFilters({
      start,
    });
  };

  const handleSelectTrip = (id: number) => {
    router.push({
      pathname: '/trips/[id]',
      query: { id, sourceUrl: sourceUrl || '/' },
    });
  };

  const isProximitySearch =
    (filters.searchField === TripSearchFields.proximityToLatLon ||
      filters.searchField === TripSearchFields.proximityToSearch) &&
    filters.distanceFrom;
  const showSearchResults = filterActive || isProximitySearch;

  return (
    <>
      <Box display="flex" mb="3">
        {isMemberLoading && <div>loading...</div>}
        {member && (
          <TripSearchControls filters={filters} onChange={setFilters} />
        )}
      </Box>

      <Box display={showSearchResults ? 'block' : 'none'} mb="3">
        <Heading level={4}>Filter results</Heading>
        <TripList
          isLoading={isLoadingTrips}
          hasError={error}
          errorMessage={
            <MessageBlock my="2" w="100%" type="error">
              Failed to load results.
            </MessageBlock>
          }
          filters={filters}
          trips={trips}
          total={data?.total}
          onCardClick={handleSelectTrip}
          emptyMessage={
            !isLoadingTrips &&
            !error && (
              <MessageBlock my="2" w="100%" type="info">
                No results found.
              </MessageBlock>
            )
          }
        />
      </Box>

      <Box mb="5">
        <Heading
          display={filterActive && !isProximitySearch ? 'block' : 'none'}
          level={4}
        >
          All results
        </Heading>
        <Box display={!isProximitySearch ? 'block' : 'none'} mb="3">
          <TripList
            isLoading={isLoadingTrips}
            hasError={error}
            errorMessage={
              <MessageBlock my="2" w="100%" type="error">
                Failed to load all results.
              </MessageBlock>
            }
            filters={filters}
            trips={initialData.current?.payload}
            total={initialData.current?.total}
            onCardClick={handleSelectTrip}
            emptyMessage={
              !isLoadingTrips &&
              !error && (
                <MessageBlock my="2" w="100%" type="info">
                  No trips found.
                </MessageBlock>
              )
            }
            onPageChanged={onPageChanged}
          />
        </Box>
      </Box>
    </>
  );
};

export default SearchableTripsList;
