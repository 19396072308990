import React from 'react';

const DiveFlag = ({
  size,
  onClick,
}: {
  size: number;
  color?: string;
  transform?: string;
  onClick?: any;
  cursor?: string;
}): React.ReactElement => (
  <svg
    viewBox="0 0 900 600"
    fill="none"
    height={size}
    onClick={onClick}
    style={{
      border: '1px solid #183298',
      borderRight: 'none',
      borderRadius: '1px',
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#fff" d="M0 0h450v600H0z" />

    <path fill="#003298" d="M900 0H450v600h450L600 300z" />
  </svg>
);

DiveFlag.defaultProps = {
  color: null,
  transform: 'none',
  onClick: null,
  cursor: 'pointer',
};

export default DiveFlag;
