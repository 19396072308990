import { Card, Select } from '@deckee/deck-hand';
import { useEffect, useState } from 'react';
import GroupMultiSelector from '../../components/GroupMultiSelector/GroupMultiSelector';
import HighestStatusMultiSelector from '../../components/HighestStatusMultiSelector/HighestStatusMultiSelector';
import { TripFilters } from '../../components/TripSearchControls/TripSearchControls';
import useGroups from '../../hooks/use-groups';
import { Group } from '../../interfaces/group';
import { TripStatus } from '../../lib/trip';

type TripDropdownFilters = Pick<
  TripFilters,
  'status' | 'groups' | 'highestStatus'
>;

interface TripFiltersFeatureProps {
  hideStatusFilter?: boolean;
  hideHighestStatusFilter?: boolean;
  onFilterChange: (filters: TripDropdownFilters) => void;
}

const TripFiltersFeature = ({
  hideStatusFilter,
  hideHighestStatusFilter,
  onFilterChange,
}: TripFiltersFeatureProps) => {
  const [statusFilters, setStatusFilters] = useState<TripStatus[]>([]);

  const [highestStatusFilters, setHighestStatusFilters] = useState<
    TripDropdownFilters['highestStatus']
  >([]);

  const [selectedBaseGroups, setSelectedBaseGroups] = useState<Group['id'][]>(
    [],
  );

  const { groups: baseGroups } = useGroups({
    types: ['base'],
    myGroups: true,
  });

  useEffect(() => {
    onFilterChange({
      status: statusFilters,
      groups: selectedBaseGroups,
      highestStatus: highestStatusFilters,
    });
  }, [statusFilters, selectedBaseGroups, highestStatusFilters, onFilterChange]);

  return (
    <Card>
      <Card.Heading size="large" level="2" mb="0">
        Filters
      </Card.Heading>

      <Card.Body>
        {!hideHighestStatusFilter && (
          <>
            <Card.Heading
              mt="0 !important"
              pb="10px !important"
              pl="0 !important"
              size="compact"
              level="3"
            >
              Highest status level
            </Card.Heading>
            <HighestStatusMultiSelector
              selectedStatuses={highestStatusFilters}
              onChange={setHighestStatusFilters}
            />
          </>
        )}

        {!hideStatusFilter && (
          <>
            <Card.Heading
              mt="0 !important"
              pb="10px !important"
              pl="0 !important"
              size="compact"
              level="3"
            >
              Status
            </Card.Heading>
            <Select
              value=""
              onChange={(e) => {
                const selectedSet = new Set([...statusFilters, e.target.value]);
                return setStatusFilters(
                  // Convert selectedSet to an array
                  Array.from(selectedSet).filter((baseId) => baseId),
                );
              }}
            >
              <Select.Option selected value=""></Select.Option>
              <Select.Option selected={false} value={TripStatus.SAR30Incident}>
                SAR Incident
              </Select.Option>
              <Select.Option selected={false} value={TripStatus.Overdue15}>
                Overdue
              </Select.Option>
              <Select.Option selected={false} value={TripStatus.Due}>
                Due
              </Select.Option>
            </Select>
          </>
        )}

        <Card.Heading
          mt="0 !important"
          pb="10px !important"
          pl="0 !important"
          size="compact"
          level="3"
        >
          Groups
        </Card.Heading>

        <GroupMultiSelector
          groups={baseGroups}
          selectedGroupIds={selectedBaseGroups}
          onChange={setSelectedBaseGroups}
        />
      </Card.Body>
    </Card>
  );
};

export default TripFiltersFeature;
