import { useCallback, useState } from 'react';
import { TripFilters } from '../../components/TripSearchControls/TripSearchControls';

const useTripFilters = () => {
  const [tripFilters, setTripFilters] = useState<
    Pick<TripFilters, 'status' | 'groups' | 'highestStatus'>
  >({});

  const handleTripFiltersChange = useCallback(
    (filters) => {
      setTripFilters(filters);
    },
    [setTripFilters],
  );

  return {
    tripFilters,
    handleTripFiltersChange,
  };
};

export default useTripFilters;
