import qs from 'qs';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { TripFilters } from '../components/TripSearchControls/TripSearchControls';
import { Trip, TripSearchFields } from '../interfaces/trip';
import { User } from '../interfaces/user';
import fetcher from '../lib/fetcher';
import { TripStatus } from '../lib/trip';

const validateFilters = (filters: Partial<TripFilters>) => {
  const { searchField, distanceFrom } = filters;

  // If searching for proximity to search or proximity to latlon, then we need to ensure that there is a `distanceFrom` configuration
  if (
    searchField === TripSearchFields.proximityToSearch ||
    searchField === TripSearchFields.proximityToLatLon
  ) {
    if (!distanceFrom?.lat || !distanceFrom?.lon) {
      return false;
    }
  }

  return true;
};

const useTrips = (member: User, alwaysFilters?: Partial<TripFilters>) => {
  const [filters, setFilters] = useState<TripFilters>({
    isLoggedOff: false,
    baseId: member?.base?.id || '',
    searchText: '',
    status: [],
    highestStatus: [],
    limit: 20,
    start: 0,
    searchField: TripSearchFields.details,

    ...alwaysFilters,
  });

  useEffect(() => {
    setFilters((prevValue) => ({
      ...prevValue,
      ...alwaysFilters,
    }));
  }, [alwaysFilters]);

  // Don't trigger the change event if the type is proximityToSearch, and we don't have a distanceTo value
  const filtersValid = validateFilters(filters);

  const { data, error, isLoading } = useSWR<{
    payload: Trip[];
    meta?: { distances?: { [key: number]: number } };
    total: number;
    limit: number;
    start: number;
  }>(
    filtersValid
      ? `/api/trips?${qs.stringify(filters, { arrayFormat: 'repeat' })}`
      : null,
    fetcher,
    {
      refreshInterval: 30000,
    },
  );

  const trips = data?.payload
    ?.map((trip) => {
      return {
        ...trip,
        // Add the baseId to the trip object
        meta: {
          distanceFrom: filters.distanceFrom,
          meters: data?.meta?.distances[trip.id],
        },
      };
    })
    .sort((a, b) => {
      if (a.meta.meters && b.meta.meters) {
        return a.meta.meters - b.meta.meters;
      }

      return 0;
    });

  return {
    trips,
    apiResponse: data,
    error,
    isLoading,
    filters,
    setFilters,
  };
};

export default useTrips;
