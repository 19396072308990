import React from 'react';

const Ruler = ({
  size,
  color = '#484848',
  transform = 'none',
  onClick,
  cursor = 'pointer',
}: {
  size: number;
  color?: string;
  transform?: string;
  onClick?: any;
  cursor?: string;
}): React.ReactElement => (
  <svg
    viewBox="0 0 17 17"
    fill="none"
    height={size}
    style={{
      cursor,
      fill: color,
      stroke: 'none',
      transform,
    }}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color || '#FF5C39'}
      d="M12.1528 0.000564932C12.0706 0.0119073 11.994 0.0530235 11.9373 0.113989L0.105288 11.9441C-0.0350962 12.0859 -0.0350962 12.3127 0.105288 12.4545L4.54087 16.8894C4.60894 16.9603 4.70395 17 4.80179 17C4.89963 17 4.99464 16.9603 5.06271 16.8894L16.8947 5.05928C17.0351 4.9175 17.0351 4.69065 16.8947 4.54887L12.4591 0.113989C12.4421 0.0927219 12.4237 0.0742906 12.4024 0.057277C12.3386 0.0161608 12.2634 -0.00368856 12.1869 0.000564932C12.1755 0.000564932 12.1642 0.000564932 12.1528 0.000564932ZM12.1982 0.885273L16.1233 4.80975L4.80179 16.1181L0.876694 12.205L1.90902 11.1728L2.79386 12.0575L3.3157 11.5358L2.43085 10.6511L3.36108 9.72101L5.33497 11.6946L5.8568 11.1728L3.88291 9.19926L4.81313 8.26918L5.69798 9.15389L6.21981 8.63214L5.33497 7.74743L6.26519 6.81735L8.23908 8.79093L8.76092 8.26918L6.78702 6.2956L7.71725 5.36552L8.6021 6.25023L9.12393 5.72848L8.23908 4.84377L9.16931 3.9137L11.1432 5.88728L11.665 5.36552L9.69114 3.39195L10.6214 2.46187L11.5062 3.34658L12.028 2.82482L11.1432 1.94012L12.1982 0.885273Z"
    />
  </svg>
);

Ruler.defaultProps = {
  color: null,
  transform: 'none',
  onClick: null,
  cursor: 'pointer',
};

export default Ruler;
