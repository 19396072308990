import { useCallback, useEffect, useRef, useState } from 'react';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE } from '../../lib/location';
import { mapboxglaccessToken, mapStyle } from '../../lib/mapbox';
import MapPin from '../map-pin';
import theme from '../theme';

const GeolocationInput = ({
  initialLocation,
  initialViewPortLatLon,
  updateSelectedLocation,
  onMoveEnd,
  ...props
}: {
  initialLocation?: {
    latitude: number;
    longitude: number;
  };
  initialViewPortLatLon?: {
    latitude: number;
    longitude: number;
  };
  updateSelectedLocation: ({ latitude, longitude }) => void;
  onMoveEnd?;
}) => {
  const mapRef = useRef(null);

  const [viewState, setViewState] = useState({
    latitude: initialLocation?.latitude || DEFAULT_LATITUDE,
    longitude: initialLocation?.longitude || DEFAULT_LONGITUDE,
    zoom: 11,
  });

  const updateViewState = useCallback((newViewState) => {
    updateSelectedLocation({
      latitude: newViewState.latitude,
      longitude: newViewState.longitude,
    });
    setViewState(newViewState);
  }, []);

  useEffect(() => {
    updateSelectedLocation({
      latitude: viewState.latitude,
      longitude: viewState.longitude,
    });
  }, []);

  useEffect(() => {
    if (initialViewPortLatLon) {
      setViewState({
        latitude: initialViewPortLatLon.latitude,
        longitude: initialViewPortLatLon.longitude,
        zoom: 11,
      });
    }
  }, [initialViewPortLatLon.latitude, initialViewPortLatLon.longitude]);

  const navControlStyle = {
    right: 10,
    top: 10,
  };

  return (
    <div style={{ position: 'relative' }}>
      <ReactMapGL
        ref={mapRef}
        style={{
          width: '100%',
          height: 450,
        }}
        initialViewState={{
          latitude: viewState.latitude,
          longitude: viewState.longitude,
          zoom: viewState.zoom,
        }}
        latitude={initialViewPortLatLon.latitude}
        longitude={initialViewPortLatLon.longitude}
        mapboxAccessToken={mapboxglaccessToken}
        mapStyle={mapStyle}
        onMove={({ viewState }) => updateViewState(viewState)}
        onMoveEnd={onMoveEnd}
      >
        <NavigationControl style={navControlStyle} />

        <Marker
          latitude={viewState?.latitude || 0}
          longitude={viewState?.longitude || 0}
          anchor="bottom"
        >
          <MapPin size={40} color={theme.colors.red} cursor="all-scroll" />
        </Marker>
      </ReactMapGL>
    </div>
  );
};

export default GeolocationInput;
