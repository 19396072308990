import { Box, LoadingIndicator } from '@deckee/deck-hand';
import React from 'react';
import { Trip } from '../../interfaces/trip';
import TripCard from '../TripCard/TripCard';
import Pagination from './pagination';
import { TripFilters } from '../TripSearchControls/TripSearchControls';
import { ApiResponse } from '../../interfaces/api';
import { error } from 'winston';

interface TripListProps {
  isLoading: boolean;
  hasError: boolean;
  trips: Trip[];
  total: ApiResponse<Trip[]>['total'];
  filters?: TripFilters;
  emptyMessage?: React.ReactNode;
  errorMessage?: React.ReactNode;
  onCardClick?: (tripId: number) => void;
  onPageChanged?: (pageData: {
    currentPage: number;
    totalPages: number;
    pageLimit: number;
  }) => void;
}

const TripList = ({
  trips,
  total,
  filters,
  emptyMessage,
  hasError,
  isLoading,
  errorMessage,
  onCardClick,
  onPageChanged,
}: TripListProps): React.ReactElement => {
  const handleCardClick = (tripId: number) => {
    onCardClick(tripId);
  };

  const handleOnPageChanged = (pageData: {
    currentPage: number;
    totalPages: number;
    pageLimit: number;
  }) => {
    if (onPageChanged) {
      onPageChanged(pageData);
    }
  };

  return (
    <Box>
      {hasError && errorMessage && errorMessage}

      {isLoading && !hasError && <LoadingIndicator />}

      {!isLoading && !hasError && trips && trips?.length === 0 && emptyMessage}

      <Box display={isLoading && 'none'}>
        {trips?.map((item: Trip, _index: number) => (
          <TripCard
            key={item.id}
            item={item}
            onClick={(trip) => handleCardClick(trip.id)}
          />
        ))}
      </Box>
      {!hasError && !isLoading && trips && (
        <Pagination
          totalRecords={total}
          pageLimit={filters.limit}
          pageNeighbours={0}
          currentPage={filters.start / filters.limit + 1}
          onPageChanged={handleOnPageChanged}
        />
      )}
    </Box>
  );
};

export default TripList;
