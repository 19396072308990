import { Trip } from '../interfaces/trip';
import { calculateTimeDifference } from './dates';

export enum TripStatus {
  None = '',
  Due = 'Due',
  Overdue15 = 'Overdue',
  SAR30Incident = 'SAR Incident',
  Unknown = 'Unknown',
  LoggedOff = 'LoggedOff',
}

export const getTripStatus = (timeDiff: number): TripStatus => {
  if (timeDiff <= -30) return TripStatus.SAR30Incident;
  if (timeDiff <= -15) return TripStatus.Overdue15;
  if (timeDiff > -15) return TripStatus.Due;
  return TripStatus.Unknown;
};

export const tripStatus = (trip: Trip): TripStatus => {
  const loggedOff = !!trip?.endedTime;
  if (loggedOff) return TripStatus.LoggedOff;
  const timeDiff = calculateTimeDifference(trip?.endTime);
  return getTripStatus(timeDiff);
};
