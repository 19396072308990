import { LocationSearchItem } from '@deckee/api-app-main';
import {
  Box,
  Card,
  InputContainer,
  InputElement,
  NavList,
} from '@deckee/deck-hand';
import debug from 'debug';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { Trip, TripSearchFields } from '../../interfaces/trip';
import { TripStatus } from '../../lib/trip';
import Can from '../can';
import GeolocationInput from '../Geolocation/geolocation-input';
import LocationSearchCard from '../LocationSearch/LocationSearchCard';
import useLocationSearch from '../LocationSearch/use-location-search';

interface TripSearchControlsProps {
  filters: TripFilters;
  onChange: (filters: TripFilters) => void;
}

export interface TripFilters {
  isLoggedOff: boolean;
  baseId: number | string;
  searchText: string;
  limit: number;
  start: number;
  status?: TripStatus[];
  highestStatus?: Trip['highestStatusLevel'][];
  groups?: string[];
  date?: string;
  distanceFrom?: {
    lat: string;
    lon: string;
  };
  searchField?: TripSearchFields;
}

const TripSearchControls = ({ onChange, filters }: TripSearchControlsProps) => {
  const updateFilters = useMemo(
    () => async (filterAndValue: Partial<TripFilters>) => {
      debug(
        '======> new filters',
        // @ts-ignore
        JSON.stringify({ ...filters, ...filterAndValue }),
      );

      const newFilters = {
        ...filters,
        ...filterAndValue,
      };

      onChange(newFilters);
    },
    [onChange, filters],
  );

  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [mapLatitude, setMapLatitude] = useState<number | null>(null);
  const [mapLongitude, setMapLongitude] = useState<number | null>(null);

  const {
    isDirty,
    isLoading,
    setIsDirty,
    setSearchTerm,
    fetchLocationDetails,
    searchTerm,
    searchLocationItems,
  } = useLocationSearch({
    latitude: 1,
    longitude: 1,
    onSearchInput: () => {},
  });

  const searchField = filters?.searchField;

  const showMap =
    (searchField === TripSearchFields.proximityToSearch ||
      searchField === TripSearchFields.proximityToLatLon) &&
    (!isDirty || !searchTerm);

  const isProximitySearch =
    searchField === TripSearchFields.proximityToLatLon ||
    searchField === TripSearchFields.proximityToSearch;

  const handleUpdateSearchTerm = (value: string) => {
    let distanceFrom = undefined;
    if (searchField === TripSearchFields.proximityToSearch) {
      setIsDirty(true);
      setSearchTerm(value);
    } else {
      setSearchTerm('');
      setIsDirty(false);
    }

    if (searchField === TripSearchFields.proximityToLatLon) {
      // Split up the value by a space. Assume that the first value is latitude, and the second value is longitude. We sohuld attempt to parse these values. If the values aren't lat/lon, then we should set the search term to the value then do nothing
      const [lat, lon] = value.split(' ');

      const parsedLat = parseFloat(lat);
      const parsedLon = parseFloat(lon);

      if (!isNaN(parsedLat) && !isNaN(parsedLon)) {
        setLatitude(parsedLat);
        setLongitude(parsedLon);
        setMapLatitude(parsedLat);
        setMapLongitude(parsedLon);

        distanceFrom = {
          lat: `${parsedLat}`,
          lon: `${parsedLon}`,
        };
      }
    }

    // Update filters, set the distanceFrom as the user has changed search term, as assume that they no longer want the previous
    updateFilters({ searchText: value, distanceFrom, start: 0 });
  };

  const handleSelectSearchLocationCard = async (
    searchLocationItem: LocationSearchItem,
  ) => {
    const { latitude, longitude } = searchLocationItem;

    let newSearchItem = searchLocationItem;

    if (searchLocationItem.placeId && !latitude && !longitude) {
      newSearchItem = await fetchLocationDetails(newSearchItem);
    }

    setIsDirty(false);
    setSearchTerm(newSearchItem.title);
    setLatitude(parseFloat(newSearchItem.latitude));
    setLongitude(parseFloat(newSearchItem.longitude));

    setMapLatitude(parseFloat(newSearchItem.latitude));
    setMapLongitude(parseFloat(newSearchItem.longitude));
    updateFilters({
      searchText: newSearchItem.title,
      start: 0,

      searchField: TripSearchFields.proximityToSearch,
    });
  };

  useEffect(() => {
    if (!latitude || !longitude) {
      updateFilters({
        distanceFrom: undefined,
      });
    } else {
      updateFilters({
        distanceFrom: {
          lat: `${latitude}`,
          lon: `${longitude}`,
        },
      });
    }
  }, [latitude, longitude]);

  const debouncedUpdateLatLon = useMemo(
    () =>
      debounce(200, ({ latitude, longitude }) => {
        setLatitude(latitude);
        setLongitude(longitude);
      }),
    [setLatitude, setLongitude],
  );

  const getPlaceholder = () => {
    if (searchField === TripSearchFields.proximityToLatLon) {
      return 'Example: -34.462211, 151.127422';
    }

    return 'Search';
  };

  return (
    <Box width="100%">
      <Can
        perform="trips:search"
        yes={() => (
          <>
            <Box
              display="flex"
              border="1px solid"
              borderColor="mediumgrey"
              borderRadius="8px"
              bg="white"
            >
              <Box
                as="select"
                name="searchField"
                id="searchField"
                m="2px"
                p="1"
                borderRadius="8px"
                fontWeight="bold"
                border="1px solid"
                borderColor="primary"
                onChange={(e: { target: { value: string } }) =>
                  updateFilters({
                    searchField: e.target.value as TripSearchFields,
                    start: 0,
                  })
                }
                defaultValue={filters.searchField}
              >
                {Object.keys(TripSearchFields).map((fieldKey) => (
                  <option
                    value={TripSearchFields[fieldKey]}
                    key={TripSearchFields[fieldKey]}
                  >
                    {TripSearchFields[fieldKey]}
                  </option>
                ))}
              </Box>
              <InputContainer
                variants="normal"
                border="none !important"
                width="100%"
                flex="1 1 0px"
              >
                <InputElement
                  as="input"
                  name="searchText"
                  id="searchText"
                  size="medium"
                  shape="round"
                  border="none !important"
                  placeholder={getPlaceholder()}
                  value={filters.searchText}
                  onChange={(e: { target: { value: string } }) =>
                    handleUpdateSearchTerm(e.target.value)
                  }
                />
              </InputContainer>
            </Box>

            {showMap && (
              <Card mt="3">
                <GeolocationInput
                  initialViewPortLatLon={
                    {
                      latitude: mapLatitude,
                      longitude: mapLongitude,
                    } as { latitude: number; longitude: number }
                  }
                  initialLocation={
                    {
                      latitude: mapLatitude,
                      longitude: mapLongitude,
                    } as { latitude: number; longitude: number }
                  }
                  updateSelectedLocation={(latLon) => {
                    setMapLatitude(latLon.latitude);
                    setMapLongitude(latLon.longitude);
                    debouncedUpdateLatLon(latLon);
                  }}
                  // Only update on move end to throttle the number of calls we need to make
                  onMoveEnd={() => {
                    // updateWhat3Words(latitude, longitude);
                    // setWaypointName(undefined);
                  }}
                />
              </Card>
            )}
            <NavList grouped>
              {searchTerm &&
                isDirty &&
                !isLoading &&
                isProximitySearch &&
                searchLocationItems &&
                searchLocationItems.length > 0 &&
                searchLocationItems.map((searchLocationItem) => (
                  <LocationSearchCard
                    key={
                      searchLocationItem.markerId || searchLocationItem.placeId
                    }
                    title={searchLocationItem.title}
                    markerType={searchLocationItem.markerType}
                    placeDescription={searchLocationItem.placeDescription}
                    onSelectCard={() =>
                      handleSelectSearchLocationCard(searchLocationItem)
                    }
                  />
                ))}
            </NavList>
          </>
        )}
      />
    </Box>
  );
};

export default TripSearchControls;
